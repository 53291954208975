const localeMap: Record<string, string> = {
  en: 'en-US',
  ca: 'es'
};

const getFullLocale = (language: string) => {
  if (!language) return undefined;
  if (localeMap[language]) return localeMap[language];
  return language;
};

export const updateZendeskWidgetLanguage = (language: string, attempt = 0) => {
  const zE = window.zE;
  let timeout: NodeJS.Timeout;
  if (!zE) {
    if (attempt > 10) {
      console.log('[Zendesk] widget not found');
      return;
    }
    timeout = setTimeout(() => updateZendeskWidgetLanguage(language, attempt + 1), 500);
    return () => clearTimeout(timeout);
  }
  try {
    console.log('[Zendesk] setting widget language to', language);
    zE('messenger:set', 'locale', getFullLocale(language));
  } catch (error) {
    console.log(error);
  }
  return () => clearTimeout(timeout);
};
