// i18next-extract-mark-ns-start paypal-for-woocommerce
//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Partners} from 'components/Partners';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';

//Partners
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import woocommerce_paypal from 'images/woocommerce_paypal.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {List} from 'components/List';
import {IndexImage} from 'components/landings/IndexImage';
import {IndexBackground} from 'components/landings/IndexBackground';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';

const IndexContent = styled.div`
  padding-top: 90px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const SellingPage: React.FC = () => {
  const {t} = useI18next();
  return (
    <>
      <SEO
        path="paypal-for-woocommerce"
        title={t('WooCommerce PayPal | MONEI')}
        description={t(
          'Add the WooCommerce PayPal integration to your store to let customers can pay with the most popular digital wallet in the world. Start here ››'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Get the WooCommerce PayPal integration</Trans>
              </SectionHeader>
              <Trans parent="p">
                PayPal is the most used digital wallet globally, making it an important online
                payment method to accept. But to do this, you’ll need a payment gateway that
                supports the WooCommerce PayPal integration.
              </Trans>
              <Trans parent="p">
                We’re here to help you. Register today to accept all the most popular payment
                methods from a single platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              src={woocommerce_paypal}
              alt="Woocommerce paypal"
              title="Woocommerce paypal"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Manage all your WooCommerce payments in one easy-to-use platform</Trans>
              </SectionHeader>
              <Trans parent="p">
                Many consumers use PayPal, but there are more{' '}
                <InternalPageLink slug="payment-methods">online payment methods</InternalPageLink>{' '}
                to add to your WooCommerce store. Shoppers want the flexibility to choose how they
                pay including PayPal and other{' '}
                <BlogLink slug="digital-wallet-ecommerce">digital wallets</BlogLink> such as{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/click-to-pay">
                  Click to Pay
                </InternalPageLink>
                ,{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  credit and debit cards
                </InternalPageLink>
                , and <BlogLink slug="local-payment-methods">local payment methods</BlogLink> like{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in Spain.
              </Trans>
              <Trans parent="p">
                We aim to help you boost customer satisfaction by accepting more payment methods.
                You’ll reach more people, sell more, and streamline managing all your online
                transactions from one easy-to-use platform.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Increase your sales revenue</Trans>
              </SectionHeader>
              <Trans parent="p">
                Reduce{' '}
                <BlogLink slug="online-payment-failure-message">online payment failures</BlogLink>{' '}
                by sending customer transactions to multiple payment processors.{' '}
                <InternalPageLink slug="features/payments-orchestration">
                  Payments orchestration
                </InternalPageLink>{' '}
                lets you set up payment processing rules based on your business needs. This way, you
                don’t have to rely on{' '}
                <InternalPageLink slug="redsys-alternative">Redsys</InternalPageLink> alone. If your
                default processor is down, we’ll route payments to the best-performing provider so
                you can provide a{' '}
                <BlogLink slug="frictionless-payments">frictionless payment</BlogLink> experience,
                approve more payments, and increase conversions.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={500}
              height={500}
              mobileWidth={280}
            />
          </Section>
          <Section>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
            <div>
              <SectionHeader>
                <Trans>Enjoy the most competitive WooCommerce payment gateway pricing</Trans>
              </SectionHeader>
              <Trans parent="p">
                Sell more and pay less on transaction fees thanks to our dynamic pricing model.
                We’re the first payment gateway offering sliding scale pricing. As your business
                grows, you’ll save money to reinvest.
              </Trans>
              <Trans parent="p">
                Use a flexible{' '}
                <InternalPageLink slug="shopify-payment-gateway">
                  Shopify payment gateway
                </InternalPageLink>{' '}
                that adapts your needs —{' '}
                <InternalPageLink slug="pricing">view pricing here</InternalPageLink>.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
        </Content>
      </Background>
      <Content fullWidth>
        <Section style={{maxWidth: 900, margin: 'auto'}}>
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>How to add the WooCommerce PayPal integration to your website</Trans>
            </SectionHeader>
            <Trans parent="p">
              Set up your WooCommerce PayPal integration by following these steps:
            </Trans>
            <List>
              <Trans parent="li">
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Sign up for MONEI here
                </AnchorLink>
              </Trans>
              <Trans parent="li">
                <AnchorLink href="https://support.monei.com/hc/es-es/sections/360005144578-PayPal">
                  Configure PayPal payments
                </AnchorLink>{' '}
                in your MONEI dashboard
              </Trans>
              <Trans parent="li">
                Follow the{' '}
                <AnchorLink href="https://docs.monei.com/docs/e-commerce/woocommerce/">
                  WooCommerce plugin guide
                </AnchorLink>{' '}
                to connect MONEI to your online store
              </Trans>
            </List>
            <Partners />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default SellingPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "paypal-for-woocommerce"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
