import {useLocation} from '@reach/router';
import {Helmet, useI18next} from 'gatsby-plugin-react-i18next';
import indexPreview from 'images/preview.png';
import React, {PropsWithRef, useEffect} from 'react';
import {updateZendeskWidgetLanguage} from 'utils/zendesk';

type Props = PropsWithRef<{
  title?: string;
  description?: string;
  suggestion?: string;
  preview?: string;
  canonical?: string;
  bodyAttributes?: Record<string, any>;
  schema?: any;
  scripts?: any;
  path?: string;
}>;

export const baseStructuredDataScript = {
  '@context': 'https://schema.org',
  '@type': 'FinancialService',
  brand: {
    '@type': 'Organization',
    url: 'https://www.monei.com/',
    name: 'MONEI',
    logo: 'https://assets.monei.com/images/logo-square.png'
  },
  logo: 'https://assets.monei.com/images/logo-square.png',
  image: 'https://assets.monei.com/images/logo-square.png',
  sameAs: [
    'https://es.linkedin.com/company/monei-digital-payments',
    'https://www.instagram.com/moneipayments/',
    'https://monei.com/blog/'
  ]
};

export const SEO: React.FC<Props> = (props) => {
  const location = useLocation();
  const {t, language} = useI18next();
  const title = props.title || t('🚀 Best Payment Platform for Business Growth');
  const description =
    props.description ||
    t(
      'Use MONEI’s plugins and APIs to accept more payment methods and increase sales online and in-person — all from a single payment platform.'
    );
  const preview = props.preview || indexPreview;
  const siteTitle = 'MONEI' + (location.pathname.includes('/monei-pay') ? ' Pay' : '');

  let structuredDataScript;

  if (props.bodyAttributes && props.bodyAttributes.class && props.bodyAttributes.class === 'blog') {
    structuredDataScript = props.schema;
  } else {
    structuredDataScript = {
      ...baseStructuredDataScript,
      name: title,
      url:
        props.canonical ||
        'https://monei.com' + (props.path ? '/' + props.path : location.pathname),
      ...props.schema
    };
  }

  useEffect(() => {
    return updateZendeskWidgetLanguage(language);
  }, [language]);

  return (
    <Helmet defer={false} titleTemplate={`%s | ${siteTitle}`} bodyAttributes={props.bodyAttributes}>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="shortcut icon" href="https://assets.monei.com/images/icon.png" />
      {/* Facebook */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={preview} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:locale" content={language} />
      <meta property="og:image:alt" content={description} />
      <meta name="robots" content="max-image-preview:large" />
      {/* Twitter */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={preview} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image:alt" content={description} />

      <script type="application/ld+json">{JSON.stringify(structuredDataScript)}</script>
      {props.scripts && <script type="application/ld+json">{JSON.stringify(props.scripts)}</script>}
    </Helmet>
  );
};
